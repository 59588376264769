@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500&display=swap');
@import 'styles/antd-overwtite.css';

* {
  box-sizing: border-box;
  margin: 0;
  font-family: 'IBM Plex Sans', sans-serif;
}

.navigation {
  display: flex;
  margin-top: 30px;
  margin-bottom: 0;
}

.prefixIcon {
  color: #8c8c8c;
}

.header {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
  text-align: center;
  color: #fa8c15;
  font-size: 18px;
  font-weight: 500 !important;
  letter-spacing: 0.1px;
}

.divider {
  display: block;
  margin-top: 24px;
  margin-bottom: 32px;
}

iframe {
  display: none !important;
}
