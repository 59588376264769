.feedback-form * {
  font-weight: 400;
}

.feedback-form #feedback {
  padding-left: 34px;
  background-image: url(../assets/message.svg);
  background-position: 10px 6px;
  background-repeat: no-repeat;
}

.feedback-form .ant-form-item {
  padding-bottom: 0;
}

.feedback-form .ant-rate-star:not(:last-child) {
  margin-right: 0;
}

.feedback-form .ant-input,
.feedback-form .ant-input-number,
.feedback-form .ant-select-selection__rendered {
  margin-bottom: 0;
  font-size: 14px;
  color: #000;
}

.feedback-form .ant-input:not(:first-child) {
  padding-left: 34px;
}

.feedback-form .ant-select-selection-selected-value {
  max-width: 225px;
}

.feedback-form .ant-form-item-label > label {
  font-size: 15px;
  font-weight: 500;
  color: #343A40;
}

.questions .ant-form-item-label {
  padding-bottom: 16px;
}

.questions .ant-form-explain {
  margin-bottom: 12px;
}

.feedback-form .ant-form-explain {
  color: #f44336;
  font-size: 12px;
  font-weight: 400;
  font-feature-settings: 'clig' off, 'liga' off;
}

.feedback-form .ant-form-item-required {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  column-gap: 2px;
}

.feedback-form .customerInformation .ant-form-item-control {
  line-height: 18px;
}

.feedback-form .react-tel-input .form-control {
  width: 100%;
  height: 40px;
  font-size: 14px;
  color: #000;
  border: 1px solid #d9d9d9;
}

.feedback-form .react-tel-input .form-control::placeholder {
  color: #BFBFBF;
}

.feedback-form .react-tel-input .flag-dropdown {
  border: 1px solid transparent;
  border-right: 1px solid #d9d9d9;
  background-color: transparent;
}

.feedback-form .react-tel-input .form-control:hover {
  border-color: #40a9ff;
}

.feedback-form .react-tel-input .form-control:focus {
  border-color: #40a9ff;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.feedback-form .react-tel-input .flag-dropdown.invalid-number {
  border-color: #f44336;
}

.feedback-form .react-tel-input .form-control.invalid-number {
  border: 1px solid #f44336;
  background-color: transparent;
}

.feedback-form .ant-calendar-picker-input,
.feedback-form .ant-time-picker-input {
  padding-left: 32px;
  color: #000;
}

.feedback-form .ant-calendar-picker-icon,
.feedback-form .ant-time-picker-icon {
  position: absolute;
  left: 10px;
  color: #8c8c8c;
}

.questions .ant-calendar-picker-icon {
  display: none;
}

.questions .ant-calendar-picker-input {
  padding-left: 11px;
}

.feedback-form .ant-time-picker-clock-icon {
  color: #8c8c8c !important;
}

.feedback-form .ant-input-prefix svg > * {
  color: #8c8c8c !important;
}

.questions .ant-rate-star-full .ant-rate-star-second svg,
.categories .ant-rate-star-full .ant-rate-star-second svg {
  fill: #1890ff;
}

.categories .ant-form-item {
  margin-bottom: 16px;
}

.categories-ex .ant-form-item {
  margin-bottom: 0;
}

.categories-ex .ant-col.ant-form-item-label {
  text-align: center;
}

@media (max-width: 768px) {
  .feedback-form .ant-select-selection-selected-value {
    max-width: 100%;
  }
}

@media (max-width: 425px) {
  .feedback-form .ant-select-selection-selected-value {
    max-width: 225px;
  }
}
